import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.scss';

import Login from './authorization/login';
import Home from './homepage/homepage';
import Attendance from './attendance/attendance';
import Homework from './homework/homework';
import Classwork from './classwork/classwork';
import Resource from './resources/resource';
import Classfeed from './feedback/feedback';
import Assignment from './assignment/assignment';
import PrivateRoute from './routes/authentication/privateRoute';
import AssignmentDetails from './assignment/assignment-details';
import Quiz from './quiz/quiz';
import CreateQuiz from './quiz/create-quiz/create-quiz';
import UpdateQuiz from './quiz/update-quiz/update-quiz';
import AttemptQuiz from './quiz/attempt-quiz/attempt-quiz';
import ResultQuiz from './quiz/result-quiz/result-quiz';

import { ability } from "./config/Can"
import defineAbilitiesFor from './config/ability';
import viewQuiz from './quiz/view-quiz/view-quiz';
import updateMarking from './progress-report/progress-report-marking/progress-report-marking';
import listReports from './progress-report/progress-report-listing/progress-report-listing';
import progressReportRemarks from './progress-report/progress-report-remarks/progress-report-remarks';
import progressReportStudents from './progress-report/progress-report-students/progress-report-students';
import progressReportStudentDetails from './progress-report/progress-report-students/progress-report-student-details/progress-report-student-details';

/*
- Create all the RESTful apis.
- Create a list page complete.
- Create a dummy forms. Update and Create.
- Check on both teacher and student side.
 */

class App extends React.Component {
  state = {
    rules: ability.update(defineAbilitiesFor(localStorage.getItem('role')))
  }

  componentDidMount() { }

  render() {
    return (
      <Router>
        <Switch>
          <Route exact path='/' component={Login} />
          <PrivateRoute path='/home' component={Home} />
          <PrivateRoute path='/resource' component={Resource} />
          <PrivateRoute path='/homework' component={Homework} />
          <PrivateRoute path='/classwork' component={Classwork} />
          <PrivateRoute path='/classfeed' component={Classfeed} />
          <PrivateRoute exact path='/quiz' component={Quiz} />
          <PrivateRoute path='/quiz/create' component={CreateQuiz} />
          <PrivateRoute path='/quiz/update/:id' component={UpdateQuiz} />
          <PrivateRoute path='/quiz/attempt/:id' component={AttemptQuiz} />
          <PrivateRoute path='/quiz/result' component={ResultQuiz} />
          <PrivateRoute path='/quiz/view/:id' component={viewQuiz} />
          <PrivateRoute exact path='/assignment' component={Assignment} />
          <PrivateRoute path='/assignment/:id' component={AssignmentDetails} />
          <PrivateRoute path='/progress-report-students' component={progressReportStudents} />
          <PrivateRoute path='/progress-report-student/:id' component={progressReportStudentDetails} />
          <PrivateRoute path='/class-marking' component={updateMarking} />
          <PrivateRoute path='/report-remarks' component={progressReportRemarks} />

          {
            this.state.rules.can('read', 'Attendance') && <PrivateRoute path='/attendance' component={Attendance} />
          }

          {
            this.state.rules.can('read', 'StudentProgress') && <PrivateRoute path='/report-listing' component={listReports} />
          }     
        </Switch>
      </Router>
    );
  }
}

export default App;