import * as types from "./types";

let initialState = {
    token: null,
    isFetching: false,
    role: null
};

export function authReducer(state = initialState, action){
    //console.log(action.type)
    switch (action.type) {
        case types.LOGIN_REQUEST:
            return { ...initialState, isFetching: true };

        case types.LOGIN_SUCCESS:
            // Store the data on Localstorage here
            return { ...state, token: action.payload.token, isFetching: false };
        
        case types.USER_LOGIN:
            return { ...state, role: action.payload, isFetching: false };    

        case types.LOGIN_FAILED:
            return { ...initialState, isFetching: false };

        case types.IS_FETCHING_REQUEST:
            return { ...initialState, isFetching: true };

        case types.IS_FETCHING_COMPLETE:
            return { ...initialState, isFetching: false };

        case types.SAVE_TOKEN:
            return { ...initialState, token: action.payload.data };

        case types.UNAUTHORIZED_USER:
            return { ...initialState, token: null };

        case types.LOGOUT:
            return { ...initialState, token: null };

        case types.ISAUTHORIZING_USER:
            return {...initialState, isFetching: false}
        default: return state
    }
}
export default authReducer;