import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { addResource, uploadImage, uploadPDF } from '../components/duck/operations';
// import Class from '../components/dropdowns/classList';
import './style.scss';
import { withTranslation } from 'react-i18next';

class resource extends React.Component {
constructor(props) {
    super(props);
    this.state = {        
        show: false,
        isImageFileAttached: false,
        isPDFFileAttached: false,
        class: null,
        heading: "", 
        content: "", 
        link: "",
        meeting: "",
        img: [],
        pdf: [],
        isPostingResource: false,
        localize: this.props.t
    }
 }

 

    onClose = e => { this.props.onClose && this.props.onClose(e); };
    onSelectClass = (event) => {  
        if(event.target.value !== '') {
            this.setState({ class: event.target.value })
        } else {
            this.setState({ class: null })
        }
    }
    handleHeading = (event) => { this.setState({heading: event.target.value}) }
    handleContent = (data) => { this.setState({content: data}) }
    handleLink = (event) => { this.setState({link: event.target.value}) }
    handleMeetingLink = (event) => { this.setState({meeting: event.target.value}) }
    handleImage = (event) => { this.setState({img : event.target.files[0], isImageFileAttached: true}) }
    handlePDF = (event) => { this.setState({pdf : event.target.files[0], isPDFFileAttached: true}) }

    //Conditions need to be updated here
    addResourceAPI = async () => {
        this.setState({ isPostingResource: true })
        try {
            if(this.validateInformationForCreating()) {
                let image, pdf;

                if(this.state.isImageFileAttached) {
                    image = await this.props.actions.uploadImage(this.state.img);
                }

                if(this.state.isPDFFileAttached) {
                    pdf = await this.props.actions.uploadPDF(this.state.pdf);
                }

                let response = await this.props.actions.addResource(
                    this.state.class, this.state.heading, this.state.content, 
                    this.state.isImageFileAttached ? image.data : '', 
                    this.state.isPDFFileAttached ? pdf.data : '', 
                    this.state.link,
                    this.state.meeting
                );

                if(response.content === "Success") {
                    this.setState({
                        class: null,
                        heading: '',
                        content: '',
                        link: '',
                        isImageFileAttached: false, isPDFFileAttached: false,
                        img: [], pdf: []
                    })
    

                    this.props.handleMessage(this.state.localize('shared.texts.created', { module: this.state.localize('pages.resource', {action: ''}) }), this.state.localize('shared.static.success'))

                    this.onClose();
                }
            } 

        } catch (error) {
            this.setState({ error: error.message || this.state.localize('shared.static.serverError') });
        }
        
        this.setState({ isPostingResource: false })
    }

    validateInformationForCreating = () => {
        if(!this.state.class) {
            this.props.handleMessage(this.state.localize('shared.texts.select', { type: this.state.localize('shared.static.class')}), this.state.localize('shared.static.warning'))

            return false;
        }

        
        if(this.state.heading.length === 0) {
            this.props.handleMessage(this.state.localize('resourceComponent.validation.heading'), this.state.localize('shared.static.warning'))

            return false;
        }

        
        
        

        return true;
    }


    render() {
        const { isPostingResource } = this.state
        return (
            <div>
            <Modal size="lg" show={this.props.show} animation={false} enforceFocus={false}>
                <Modal.Header>
                    <Modal.Title>{this.state.localize('pages.resource', {action: this.state.localize('shared.static.create')})}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Container>
                        <Row>
                            <Col xs={12} md={12} lg={12}>
                                <Modal.Body className="form-group required">
                                    <label className="control-label">{this.state.localize('resourceComponent.shared.class')} </label>
                                    <select className="form-control form-sm"  onChange={this.onSelectClass}>
                                        <option value="">{this.state.localize('shared.texts.select', {type: this.state.localize('shared.static.class')})}</option>
                                        {
                                            this.props.classes.map((c, i) => <option value={c._id} key={i}>{c.name}</option>)
                                        }
                                    </select>
                                </Modal.Body>
                            </Col>
                            <Col xs={12} md={12} lg={12}>
                                <Modal.Body className="form-group required">
                                    <label className="control-label">{this.state.localize('resourceComponent.shared.heading')} </label>
                                    <textarea rows="1" onChange={this.handleHeading}></textarea>
                                </Modal.Body>
                            </Col>
                            <Col xs={12} md={12} lg={12}>
                                <Modal.Body className="form-group">
                                    <label className="control-label">{this.state.localize('resourceComponent.shared.content')} </label>
                                    {/* <textarea onChange={this.handleContent}></textarea> */}
                                    <CKEditor 
                                            editor={ClassicEditor} 
                                            data=""
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                this.handleContent(data)
                                            }}
                                        />
                                </Modal.Body>
                            </Col>
                        </Row>
                        <Row>
                            <Container>
                                <Col xs={12} md={12} lg={6}>
                                    <Modal.Body className="form-group">
                                        <label className="control-label" > {this.state.localize('resourceComponent.shared.attachment')} </label>
                                    </Modal.Body>
                                </Col>
                                <Col xs={12} md={12} lg={6}>
                                    <Modal.Body className="form-group">
                                        <label className="control-label" > {this.state.localize('resourceComponent.shared.selectImage')}
                                            <input type="file" accept="image/*" onChange={this.handleImage}/>
                                        </label>
                                    </Modal.Body>
                                </Col>
                                <Col xs={12} md={12} lg={6}>
                                    <Modal.Body className="form-group">
                                        <label className="control-label"> {this.state.localize('resourceComponent.shared.chooseFile')}
                                            <input type="file" onChange={this.handlePDF}/>
                                        </label>
                                    </Modal.Body>
                                </Col>
                            </Container>
                        </Row>
                        <Row>
                            <Col xs={12} md={12} lg={12}>
                                <Modal.Body>
                                    <label>{this.state.localize('resourceComponent.shared.link')}</label>
                                    <textarea rows="1" onChange={this.handleLink}></textarea>
                                </Modal.Body>
                            </Col>

                            <Col xs={12} md={12} lg={12}>
                                <Modal.Body>
                                    <label>Meeting Link:</label>
                                    <textarea rows="1" onChange={this.handleMeetingLink}></textarea>
                                </Modal.Body>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={this.onClose}>
                        { this.state.localize('shared.buttons.close') }
                    </Button>

                    <Button
                        variant="success"
                        onClick={this.addResourceAPI}
                        disabled={isPostingResource}>
                        {!isPostingResource ? <FontAwesomeIcon icon={faPlus} /> : <FontAwesomeIcon icon={faCircleNotch} spin />} 
                        {!isPostingResource ? (' ' + this.state.localize('shared.buttons.add')) : (' ' + this.state.localize('shared.buttons.wait'))}
                    </Button>
                </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        classID: state.componentReducer.classID,
        image: state.componentReducer.image,
        pdf: state.componentReducer.pdf
    }
}

function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators({ 
          addResource: addResource,
          uploadImage: uploadImage,
          uploadPDF: uploadPDF 
        }, dispatch)
    };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(resource));