/* USER LOGIN */
export const USER_LOGIN = "USER_LOGIN";

/* LOGIN */
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";

/* FETCHING RESULT */
export const IS_FETCHING_REQUEST = "IS_FETCHING_REQUEST";
export const IS_FETCHING_COMPLETE = "IS_FETCHING_COMPLETE";

/* STORE TOKEN IN REDUX */
export const SAVE_TOKEN = "SAVE_TOKEN";

/* UNAUTHORIZED */
export const UNAUTHORIZED_USER = "UNAUTHORIZED_USER";

/* LOG OUT */
export const LOGOUT = "LOGOUT";

export const ISAUTHORIZING_USER = "ISAUTHORIZING_USER"