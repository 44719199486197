import React from 'react';
import { connect } from 'react-redux';
import { Container, Button, Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { updateAssignment } from '../components/duck/operations';
import { withTranslation } from 'react-i18next';

class assignmentUpdate extends React.Component {
    state = { isUpdatingAssignment: false, date: null, localize: this.props.t }

    onClose = e => { this.props.onClose && this.props.onClose(e); };

    handleUpdateAssignment = async () => {
        this.setState({ isUpdatingAssignment: true })
        try {
            let isAssignmentUpdate = await this.props.actions.updateAssignment( this.props.assignmentID, this.state.date || this.props.date );
            if(isAssignmentUpdate){ 
                this.onClose()
                this.props.onUpdate()

                this.props.showToastError('Assignment Updated Successfully', 'Success');
             }
        } catch (error) {
            this.setState({ error: error.message || "Server Error" });

            this.props.showToastError(error?.response?.data?.message || "Server Error", 'Success');
        }
        this.setState({ isUpdatingAssignment: false })
    }

    handleDate = (e) => {
        this.setState({
            ...this.state,
            date: e.target.value
        })
    }

    render() {
        const { isUpdatingAssignment, date } = this.state
        return (
            <div>
                <Modal show={this.props.show} animation={false}>
                    <Modal.Header>
                        <Modal.Title>{this.state.localize('assignmentComponent.shared.updateDate')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container> 
                            {/* <Date date={this.state.date}/>  */}
                            <input 
                                className="dateelement" 
                                type="date" 
                                id="todaydate" 
                                name="todaydate"
                                onChange={this.handleDate} 
                                value={ date || this.props.date }/>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={this.onClose}>
                            { this.state.localize('shared.buttons.close') }
                        </Button>
                        <Button
                            variant="success"
                            onClick={this.handleUpdateAssignment}
                            disable={isUpdatingAssignment}>
                            {!isUpdatingAssignment ? "" : <FontAwesomeIcon icon={faCircleNotch} spin />} 
                            {!isUpdatingAssignment ? (' ' + this.state.localize('shared.buttons.submit')) : (' ' + this.state.localize('shared.buttons.wait'))} 
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps() {
    return {
        /* date: state.componentReducer.date, */
        /* assignmentID: state.componentReducer.assignmentID */
    }
}

function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators({ updateAssignment: updateAssignment }, dispatch)
    };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(assignmentUpdate));