import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { uploadImage, uploadPDF, updateResource } from '../components/duck/operations';
import './style.scss';
import { withTranslation } from 'react-i18next';
var textEditorResourceContent = '';
class ResourceUpdate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            isImageFileAttached: false,
            isPDFFileAttached: false,
            reuploadImageFile: false,
            reuploadDocFile: false,
            classID: null,
            heading: "",
            content: "",
            link: "",
            img: "",
            pdf: "",
            isUpdatingResource: false,
            localize: this.props.t
        }
    }


    onClose = () => { this.props.onClose() };
    onSelectClass = (event) => {
        if (event.target.value !== '') {
            this.setState({ classID: event.target.value })
        } else {
            this.setState({ classID: null })
        }
    }
    handleHeading = (event) => {
        let resource = { ...this.state.resource };

        resource.heading = event.target.value;

        this.setState({ resource })
    }
    handleContent = (data) => {
        textEditorResourceContent = data;       
    }
    handleLink = (event) => {
        let resource = { ...this.state.resource };

        resource.video = event.target.value;

        this.setState({ resource })
    }
    handleMeetingLink = (event) => {
        let resource = { ...this.state.resource };

        resource.meetingUrl = event.target.value;

        this.setState({ resource })
    }
    handleImage = (event) => { 
        let files = [...event.target.files];
        
        this.setState({ 
            img: event.target.files[0], 
            isImageFileAttached: files.length > 0 ? true : false, 
            reuploadImageFile: files.length > 0 ? true : false 
        }) 
    }

    handlePDF = (event) => {    
        let files = [...event.target.files];
        
        this.setState({ 
            pdf: event.target.files[0], 
            isPDFFileAttached: files.length > 0 ? true : false, 
            reuploadDocFile: files.length > 0 ? true : false 
        }) 
    }
    
    componentDidUpdate(nextProps) {
        if(nextProps.resource !== this.props.resource && this.props.resource) {
            this.setState({
                resource: this.props.resource,
                isImageFileAttached: this.props.resource.img.length > 0 ? true : false,
                isPDFFileAttached: this.props.resource.img.length > 0 ? true : false,
                classID: this.props.classID
            })
        }
    }

    componentDidMount(content) {  
    
        new Promise( ( resolve, reject ) => {
          setTimeout( resolve, 100 );
        } ).then( () => {
          this.setState( { desc: content} );
        } );
      }

    updateResourceAPI = async () => {
        let resource = { ...this.state.resource };
        resource.text = textEditorResourceContent;
        this.setState({ resource })
        this.setState({ isUpdatingResource: true })

        try {
            if (this.validateInformationForCreating()) {
                //let resource = { ...this.state.resource }; DUPLICATE, not needed

                let image, pdf;

                if (this.state.reuploadImageFile) {
                    image = await this.props.actions.uploadImage(this.state.img);
                    resource.img = image.data;
                }

                if (this.state.reuploadDocFile) {
                    pdf = await this.props.actions.uploadPDF(this.state.pdf);
                    resource.file = pdf.data;
                }

                const resourcePayload = {
                    "resourceID": resource._id,
                    "authorID": resource.authorID,
                    "schoolID": localStorage.getItem('schoolID'),
                    "classID": this.state.classID || this.props.classID,
                    "authorType": "Teacher",
                    "date": resource.date,
                    "heading": resource.heading,
                    "content": resource.text,
                    "img": resource.img,
                    "file": resource.file,
                    "video": resource.video,
                    "meetingUrl": resource.meetingUrl
                };

                const response = await this.props.actions.updateResource(resourcePayload);

                if (response.status === 200) {
                    this.setState({
                        image: "", isImageFileAttached: false, reuploadImageFile: false, 
                        pdf: "", isPDFFileAttached: false, reuploadDocFile: false
                    })

                    this.props.handleMessage(this.state.localize('shared.texts.updated', { module: this.state.localize('pages.resource', {action: ''}) }), this.state.localize('shared.static.success'))

                    this.props.onUpdate()
                }
            }
        } catch (error) {
            this.setState({ error: error.message || this.state.localize('shared.static.serverError') });
        }
        this.setState({ isUpdatingResource: false })
    }

    validateInformationForCreating = () => {
        if (!this.state.classID) {
            this.props.handleMessage(this.state.localize('shared.texts.select', { type: this.state.localize('shared.static.class')}), this.state.localize('shared.static.warning'))

            return false;
        }

        if (this.state.resource.heading.length === 0) {
            this.props.handleMessage(this.state.localize('resourceComponent.validation.heading'), this.state.localize('shared.static.warning'))

            return false;
        }

        

        return true;
    }

    render() {
        const { isUpdatingResource, resource } = this.state;
        this.componentDidMount(resource?.text);
        return (
            <div>
                <Modal size="lg" show={this.props.show} animation={false} enforceFocus={false}>
                    <Modal.Header>
                        <Modal.Title>{this.state.localize('pages.resource', {action: this.state.localize('shared.static.update')})}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="show-grid">
                        <Container>
                            <Row>
                                <Col xs={12} md={12} lg={12}>
                                    <Modal.Body className="form-group required">
                                        <label className="control-label">{this.state.localize('resourceComponent.shared.class')} </label>
                                        <select className="form-control form-sm" onChange={this.onSelectClass} value={this.state.classID || this.props.classID}>
                                            <option value="">{this.state.localize('shared.texts.select', {type: 'One'})}</option>
                                            { this.props.classes.map((c, i) => <option value={c._id} key={i}>{c.name}</option>) }
                                        </select>
                                    </Modal.Body>
                                </Col>
                                <Col xs={12} md={12} lg={12}>
                                    <Modal.Body className="form-group required">
                                        <label className="control-label">{this.state.localize('resourceComponent.shared.heading')} </label>
                                        <textarea rows="1" onChange={this.handleHeading} value={resource?.heading}></textarea>
                                    </Modal.Body>
                                </Col>
                                <Col xs={12} md={12} lg={12}>
                                    <Modal.Body className="form-group required">
                                        <label className="control-label">{this.state.localize('resourceComponent.shared.content')} </label>
                                        {/* <textarea onChange={this.handleContent} value={resource?.text}></textarea> */}
                                        <CKEditor 
                                            editor={ClassicEditor} 
                                            data={this.state.desc}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                this.handleContent(data)
                                            }}
                                        />
                                    </Modal.Body>
                                </Col>
                            </Row>
                            <Row>
                                <Container>
                                    <Col xs={12} md={12} lg={6}>
                                        <Modal.Body className="form-group">
                                            <label className="control-label" > {this.state.localize('resourceComponent.shared.attachment')} </label>
                                        </Modal.Body>
                                    </Col>
                                    <Col xs={12} md={12} lg={6}>
                                        <Modal.Body className="form-group">
                                            {
                                                resource?.img?.length > 0 ?
                                                    <div>
                                                        <div>
                                                            <img src={resource.img} alt="assignment" style={{ width: '128px', height: '128px' }} />
                                                        </div>

                                                        <label className="control-label" >{this.state.localize('resourceComponent.shared.selectImage')}
                                                    <input type="file" accept="image/*" onChange={this.handleImage} />
                                                        </label>
                                                    </div>
                                                    :
                                                    <div>
                                                        <label className="control-label" > {this.state.localize('resourceComponent.shared.selectImage')}
                                                    <input type="file" accept="image/*" onChange={this.handleImage} />
                                                        </label>
                                                    </div>
                                            }
                                        </Modal.Body>
                                    </Col>
                                    <Col xs={12} md={12} lg={6}>
                                        <Modal.Body className="form-group">
                                            {
                                                resource?.file?.length > 0 ?
                                                    <div>
                                                        <div>
                                                            <a href={resource.file} target="_blank" rel="noopener noreferrer"> {this.state.localize('resourceComponent.static.downloadFile')} </a>
                                                        </div>
                                                        <label className="control-label" > {this.state.localize('resourceComponent.shared.chooseFile')}
                                                <input type="file" onChange={this.handlePDF} />
                                                        </label>
                                                    </div>
                                                    :
                                                    <div>
                                                        <label className="control-label"> {this.state.localize('resourceComponent.shared.chooseFile')}
                                                <input type="file" onChange={this.handlePDF} />
                                                        </label>
                                                    </div>
                                            }
                                        </Modal.Body>
                                    </Col>
                                </Container>
                            </Row>
                            <Row>
                                <Col xs={12} md={12} lg={12}>
                                    <Modal.Body>
                                        <label>{this.state.localize('resourceComponent.shared.link')}</label>
                                        <textarea rows="1" onChange={this.handleLink} value={resource?.video ?? ''}></textarea>
                                    </Modal.Body>
                                </Col>

                                <Col xs={12} md={12} lg={12}>
                                    <Modal.Body>
                                        <label>Meeting Link:</label>
                                        <textarea rows="1" onChange={this.handleMeetingLink} value={resource?.meetingUrl ?? ''}></textarea>
                                    </Modal.Body>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={this.onClose}>
                            { this.state.localize('shared.buttons.close') }
                    </Button>

                        <Button
                            variant="success"
                            onClick={this.updateResourceAPI}
                            disabled={isUpdatingResource}>
                            {!isUpdatingResource ? <FontAwesomeIcon icon={faPlus} /> : <FontAwesomeIcon icon={faCircleNotch} spin />}
                            {!isUpdatingResource ? (' ' + this.state.localize('shared.buttons.update')) : (' ' + this.state.localize('shared.buttons.wait'))}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps() {
    return {
        /* classID: state.componentReducer.classID, */
        /* image: state.componentReducer.image,
        pdf: state.componentReducer.pdf */
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            updateResource: updateResource,
            uploadImage: uploadImage,
            uploadPDF: uploadPDF
        }, dispatch)
    };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ResourceUpdate));