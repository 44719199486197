import React from 'react';
import { Container, Row, Col, Button, Toast } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { faSearch, faPlus, faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom';
import moment from 'moment';
import HeaderBar from '../components/HeaderBar';
/* import Class from '../components/dropdowns/classList';
import Subject from '../components/dropdowns/subject'; */
import AssignmentForm from './assignment-form';
import Can from "../config/Can";
import './style.scss';
import { fetchAssignment, fetchClasses, fetchSubject } from '../components/duck/operations';
import { fetchAssignmentID } from '../components/duck/actions';
import { withTranslation } from 'react-i18next';
import i18n from '../i18n';

class AssignmentList extends React.Component {
    state = {
        show: false,
        assignments: [],
        isFetchingAssignment: false,
        classID: '', subjectID: '',
        classes: [], subjects: [],
        toast: {
            show: false,
            message: null,
            heading: null
        },
        messageBox: this.props.t('assignmentComponent.static.selectFilter'),
        localize: this.props.t
    }

    componentDidMount() {
        if(localStorage.getItem('role') === 'teacher') {
            this.getClasses();
        } else {
            this.getSubjects();
        }
    }

    hideToast = () => {
        this.setState({
            toast: {
                show: false,
                message: null,
                heading: null
            },
            isFetchingAssignment: false
        })
    }

    async getClasses() {
        this.setState({
            isFetchingAssignment: true
        })

        try {
            const classes = await this.props.actions.fetchClasses();

            this.setState({
                classes
            })
        } catch (error) {
            this.setState({
                toast: {
                    show: !this.state.toast.show,
                    message: error.response.data.message,
                    heading: "Warning"
                }
            });

            setTimeout(this.hideToast, 3000);
        }

        this.setState({
            isFetchingAssignment: false
        })
    }

    async getSubjects() {
        this.setState({
            isFetchingAssignment: true
        })

        try {
            let classID;

            if (localStorage.getItem('role') === 'teacher') { classID = this.state.classID; }
            else if (localStorage.getItem('role') === 'student') { classID = localStorage.getItem('classID'); }

            if (classID) {
                const subjects = await this.props.actions.fetchSubject(classID);
                if (subjects.length > 0) {
                    this.setState({ subjects });
                }
            }
        } catch (error) {
            /* this.setState({ error: error.message || this.state.localize('shared.static.serverError') }); */

            console.log(error)

            /* this.setState({
                toast: {
                    show: !this.state.toast.show,
                    message: error.response.data.message
                }
            }); */

            setTimeout(this.hideToast, 3000);
        }

        this.setState({
            isFetchingAssignment: false
        })
    }

    onSelectClass = (e) => {
        if (e.target.value) {
            if (e.target.value !== "Select Class") {
                this.setState({ classID: e.target.value }, () => {
                    this.getSubjects()
                });
            }

        }
    }

    onSelectSubject = (e) => {
        if (e.target.value) {
            if (e.target.value !== "Select Subject") {
                this.setState({ subjectID: e.target.value });
            }
        }
    }

    setAssignmentID = (e) => { this.props.actions.fetchAssignmentID(e.target.id); }

    showModal = () => { this.setState({ show: !this.state.show }); };
    fetchAssignment = async () => {
        this.setState({ isFetchingAssignment: true })
        try {
            this.setState({ assignments: [] })
            let classID; 
            if( localStorage.getItem('role') === 'teacher' ){ 
                if (!this.state.classID) {
                    this.setState({
                        toast: {
                            show: !this.state.toast.show,
                            message: this.state.localize('shared.static.atleastClass'),
                            title: this.state.localize('shared.static.warning')
                        }
                    });
    
                    setTimeout(this.hideToast, 3000);
    
                    return;
                }
                
                classID = this.state.classID; 
            }
            else if( localStorage.getItem('role') === 'student' ){ classID = localStorage.getItem('classID'); }

            if(!this.state.subjectID) {
                this.setState({
                    toast: {
                        show: !this.state.toast.show,
                        message: this.state.localize('shared.static.atleastSubject'),
                        title: this.state.localize('shared.static.warning')
                    }
                });

                setTimeout(this.hideToast, 3000);

                return;
            }

            let studentAssignment = await this.props.actions.fetchAssignment(classID, this.state.subjectID);
            this.setState({ assignments: studentAssignment.list });       
            
            if(studentAssignment.list.length <= 0) {
                this.setState({
                    messageBox: this.props('assignmentComponent.static.noAssignment')
                })
            }
        } catch (error) {
            this.setState({ error: error.message || this.state.localize('shared.static.serverError') });
        }
        this.setState({ isFetchingAssignment: false })
    }

    showErrorInToast = (message, heading) => {
        if(message === '') return;

        this.setState({
            toast: {
                show: !this.state.toast.show,
                message,
                heading
            }
        });

        setTimeout(this.hideToast, 3000);
    }

    renderAssignmentList(assignments){
        if(assignments.length<1){
            return <div className="list-container-message-box"> <h3> { this.state.localize('assignmentComponent.static.selectFilter') } </h3> </div> 
        }

        return <div className="assignment-list__wrapper" style={{margin: '0px 18px'}}>
            <div className="assignment-list__heading">
                <b>{ this.state.localize('assignmentComponent.tableHeadings.title') }</b>
                <b>{ this.state.localize('assignmentComponent.tableHeadings.deadline') }</b>
                <b className="text-right"></b>
            </div>

            <div>
                {assignments.length > 0 && assignments.map((assignment) => {
                        return (
                        <div className="assignment-list__row" key={assignment._id}>
                            <span dangerouslySetInnerHTML={{__html: assignment.title}}></span>
                            <span>{moment(assignment.dead_line).locale(i18n.language).format('MM/DD/YYYY')}</span>
                            <span className="text-right">
                                <Link to={{ pathname: `/assignment/${assignment._id}`}} >
                                    <Button variant="primary" id={assignment._id} onClick={this.setAssignmentID}> {this.state.localize('shared.buttons.detail')}</Button>
                                </Link>
                            </span>
                    </div> 
                    )})} 
                </div>
    </div>
    }

    render() {
        const { isFetchingAssignment, assignments, show } = this.state
        return (
            <div>
                <HeaderBar />
                <div
                    aria-live="polite"
                    aria-atomic="true">
                    <div
                        style={{
                            marginTop: '10px',
                            marginRight: '10px',
                            position: 'fixed',
                            top: 0,
                            right: 0,
                            zIndex: '9999',
                            width: '280px'
                        }}
                    >
                        <Toast show={this.state.toast.show}>
                            <Toast.Header>
                                <strong className="mr-auto">{ this.state.toast.heading }</strong>
                            </Toast.Header>
                            <Toast.Body>{this.state.toast.message}</Toast.Body>
                        </Toast>
                    </div>
                </div>
                <div className="assignment-list__container">
                    <Container fluid={true}>
                        <Row>
                            <Col xs={12} lg={1} md={12}>
                                <h3>{this.state.localize('pages.assignment')}</h3>
                            </Col>

                            <Col xs={12} lg={11} md={12} className="d-flex justify-content-end" style={{padding: '0px'}}>
                                <Can I='read' a='Class'> 
                                    <div style={{marginRight: '5px', marginLeft: '8px'}}>
                                        {/* <Class default="" onSelect={this.onSelectClass}/> */}
                                        <select className="form-control form-sm" onChange={this.onSelectClass} disabled={isFetchingAssignment} value={this.state.classID}>
                                            <option>{ this.state.localize('shared.texts.select', {type: this.state.localize('shared.static.class')}) }</option>
                                            {this.state.classes.map((c) => <option value={c._id} key={c._id}>{c.name}</option> )}
                                        </select>
                                    </div>
                                </Can>

                                <div style={{marginRight: '5px', marginLeft: '8px'}}>
                                    {/* <Subject default={this.state.class} onSelect={this.onSelectSubject}/> */}
                                    <select className="form-control form-sm" onChange={this.onSelectSubject} disabled={isFetchingAssignment} value={this.state.subjectID}>
                                        <option>{ this.state.localize('shared.texts.select', {type: this.state.localize('shared.static.subject')}) }</option>
                                        {this.state.subjects.map((s) => <option value={s._id} key={s._id}>{s.title}</option>)}
                                    </select>
                                </div>

                                <div>
                                    <Button style={{marginRight: '5px', marginLeft: '8px'}}
                                        className="search-button"
                                        onClick={this.fetchAssignment}
                                        disabled={isFetchingAssignment}>
                                        {!isFetchingAssignment ? <FontAwesomeIcon icon={faSearch} /> : <FontAwesomeIcon icon={faCircleNotch} spin />}
                                        {!isFetchingAssignment ? (' ' + this.state.localize('shared.buttons.search')) : (' ' + this.state.localize('shared.buttons.wait'))}
                                    </Button>
                                </div>

                                <Can I='create' a='Assignment'>
                                    <div>
                                        <Button disabled={isFetchingAssignment} style={{marginRight: '5px', marginLeft: '8px'}} className="search-button" onClick={() => { this.showModal(); }}> <FontAwesomeIcon icon={faPlus} /> { this.state.localize('pages.assignment', { action: ''})} </Button>
                                    </div>
                                </Can>
                            </Col>
                        </Row>
                    </Container>
                    {/* <div className="assignment-list__header">
                        <h3>Assignment</h3>
                        <div className="action-button__wrapper">
                            <Can I='read' a='Class'> 
                                <Class default="" onSelect={this.onSelectClass}/>
                            </Can>
                                <Subject default={this.state.class} onSelect={this.onSelectSubject}/>
                            <Button
                                className="search-button"
                                onClick={this.fetchAssignment}
                                disable={isFetchingAssignment.toString()}>
                                {!isFetchingAssignment ? <FontAwesomeIcon icon={faSearch} /> : <FontAwesomeIcon icon={faCircleNotch} spin />}
                                {!isFetchingAssignment ? (' Search') : (' Wait')}
                            </Button>
                            <Can I='create' a='Assignment'>
                                <Button className="search-button" onClick={() => { this.showModal(); }}> <FontAwesomeIcon icon={faPlus} /> Assignment </Button>
                            </Can>
                        </div>
                    </div> */} {/* Assignment Header closed */}

                    <AssignmentForm onClose={this.showModal} show={show} handleMessage={this.showErrorInToast} classes={this.state.classes}/>
                    {
                        isFetchingAssignment ?
                            <div className="text-xl-center">
                                <FontAwesomeIcon style={{ width: '200px', height: '200px', color: '#5bc0de' }} icon={faCircleNotch} spin />
                            </div> :
                            this.renderAssignmentList(assignments)
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        date: state.componentReducer.date,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
             fetchAssignment: fetchAssignment,
             fetchAssignmentID: fetchAssignmentID,
             fetchClasses: fetchClasses,
             fetchSubject: fetchSubject
        }, dispatch)
    };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AssignmentList));