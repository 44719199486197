import {
    FETCH_CLASSES_PENDING, FETCH_CLASSES_SUCCESS, FETCH_CLASSES_ERROR, 
    FETCH_SUBJECT_PENDING, FETCH_SUBJECT_SUCCESS, FETCH_SUBJECT_ERROR, 
    // FETCH_CLASS_ID, FETCH_SUBJECT_ID, 
    FETCH_STUDENT, FETCH_DATE, 
    FETCH_IMAGE_PENDING, FETCH_IMAGE_SUCCESS, FETCH_IMAGE_ERROR,
    FETCH_PDF_PENDING, FETCH_PDF_SUCCESS, FETCH_PDF_ERROR,
    FETCH_ATTENDANCE_PENDING, FETCH_ATTENDANCE_SUCCESS, FETCH_ATTENDANCE_ERROR,
    FETCH_HOMEWORK_PENDING, FETCH_HOMEWORK_SUCCESS, FETCH_HOMEWORK_ERROR,
    FETCH_CLASSWORK_PENDING, FETCH_CLASSWORK_SUCCESS, FETCH_CLASSWORK_ERROR,
    MARK_ATTENDANCE_PENDING, MARK_ATTENDANCE_SUCCESS, MARK_ATTENDANCE_ERROR,
    FETCH_RESOURCE_PENDING, FETCH_RESOURCE_SUCCESS, FETCH_RESOURCE_ERROR,
    FETCH_FEEDBACK_PENDING, FETCH_FEEDBACK_SUCCESS, FETCH_FEEDBACK_ERROR,
    FETCH_ASSIGNMENT_PENDING, FETCH_ASSIGNMENT_SUCCESS, FETCH_ASSIGNMENT_ERROR,
    FETCH_ASSIGNMENT_ID, FETCH_QUIZ_ID,
    FETCH_QUIZ_PENDING, FETCH_QUIZ_SUCCESS, FETCH_QUIZ_ERROR

} from './types';

const initialState = {
    classList: [], classID: [],
    subjectID: [], subjectList: [],
    student: [], status: [], studentID: [],
    attendanceList: [], homeworkList: [], classworkList: [],
    resourceList: [], feedbackList: [], assignmentList: [], assignmentID: [],
    image: [], pdf: [], date: null, error: null, pending: false,
    quizList: [], quizID: null,
}

export function componentReducer(state = initialState, action) {
    console.log(action.type)
    switch(action.type) {
        case FETCH_CLASSES_PENDING: 
            return { ...state, pending: true }
        case FETCH_CLASSES_SUCCESS:
            return { ...state, pending: false, classList: action.payload }
        case FETCH_CLASSES_ERROR:
            return { ...state, pending: false, error: action.error }
        case FETCH_SUBJECT_PENDING: 
            return { ...state, pending: true }
        case FETCH_SUBJECT_SUCCESS:
            return { ...state, pending: false, subjectList: action.payload }
        case FETCH_SUBJECT_ERROR:
            return { ...state, pending: false, error: action.error }
        case FETCH_DATE: 
            return { ...state, date: action.payload }     
        case FETCH_ATTENDANCE_PENDING: 
            return { ...state, pending: true }
        case FETCH_ATTENDANCE_SUCCESS:
            return { ...state, pending: false, attendanceList: action.payload }
        case FETCH_ATTENDANCE_ERROR:
            return { ...state, pending: false, error: action.error }
        case FETCH_STUDENT: 
            return { ...state, student: action.payload, status: action.status, studentID: action.studentID }     
        case FETCH_HOMEWORK_PENDING: 
            return { ...state, pending: true }
        case FETCH_HOMEWORK_SUCCESS:
            return { ...state, pending: false, homeworkList: action.payload }
        case FETCH_HOMEWORK_ERROR:
            return { ...state, pending: false, error: action.error }
        case FETCH_CLASSWORK_PENDING: 
            return { ...state, pending: true }
        case FETCH_CLASSWORK_SUCCESS:
            return { ...state, pending: false, classworkList: action.payload }
        case FETCH_CLASSWORK_ERROR:
            return { ...state, pending: false, error: action.error }
        case MARK_ATTENDANCE_PENDING: 
            return { ...state, pending: true }
        case MARK_ATTENDANCE_SUCCESS:
            return { ...state, pending: false }
        case MARK_ATTENDANCE_ERROR:
            return { ...state, pending: false, error: action.error }
        case FETCH_RESOURCE_PENDING: 
            return { ...state, pending: true }
        case FETCH_RESOURCE_SUCCESS:
            return { ...state, pending: false, resourceList: action.payload }
        case FETCH_RESOURCE_ERROR:
            return { ...state, pending: false, error: action.error }
        case FETCH_FEEDBACK_PENDING: 
            return { ...state, pending: true }
        case FETCH_FEEDBACK_SUCCESS:
            return { ...state, pending: false, feedbackList: action.payload }
        case FETCH_FEEDBACK_ERROR:
            return { ...state, pending: false, error: action.error }
        case FETCH_IMAGE_PENDING: 
            return { ...state, pending: true }
        case FETCH_IMAGE_SUCCESS:
            return { ...state, pending: false, image: action.payload }
        case FETCH_IMAGE_ERROR:
            return { ...state, pending: false, error: action.error }
        case FETCH_PDF_PENDING: 
            return { ...state, pending: true }
        case FETCH_PDF_SUCCESS:
            return { ...state, pending: false, pdf: action.payload }
        case FETCH_PDF_ERROR:
            return { ...state, pending: false, error: action.error }
        case FETCH_ASSIGNMENT_PENDING: 
            return { ...state, pending: true }
        case FETCH_ASSIGNMENT_SUCCESS:
            return { ...state, pending: false, assignmentList: action.payload }
        case FETCH_ASSIGNMENT_ERROR:
            return { ...state, pending: false, error: action.error }
        case FETCH_ASSIGNMENT_ID: 
            return { ...state, assignmentID: action.payload } 
        case FETCH_QUIZ_PENDING: 
            return { ...state, pending: true }
        case FETCH_QUIZ_SUCCESS:
            return { ...state, pending: false, quizList: action.payload }
        case FETCH_QUIZ_ERROR:
            return { ...state, pending: false, error: action.error }
        case FETCH_QUIZ_ID: 
            return { ...state, quizID: action.payload } 

        default: 
            return state;
    }
}

        // case FETCH_CLASS_ID: 
        //     return { ...state, classID: action.payload }     
        // case FETCH_SUBJECT_ID: 
        //     return { ...state, subjectID: action.payload }     
