import React from 'react';
import { withTranslation } from 'react-i18next';

import HeaderBar from '../../components/HeaderBar';
import { Container, Row, Col, Button, Toast } from 'react-bootstrap';

import { fetchClasses, getTemplates, getRemarksOfStudents, updateRemark, createRemark, deleteProgressReportRemark } from '../../components/duck/operations';

import './style.scss';
import { bindActionCreators } from 'redux';
import { connect, FontAwesomeIcon, faCircleNotch, faTrashAlt } from '../../quiz';

import RemarkAllModal from './remark-all/remark-all-modal';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class progressReportRemarks extends React.Component {
    state = {
        classes: [],
        remarks: [], remarksCopy: [],
        toast: {
            show: false,
            title: null,
            message: null
        },
        remarksModal: {
            show: false,
            remark: ''
        },
        localize: this.props.t,
        message: this.props.t('progressReportRemarksComponent.static.selectFilter'),
        classID: null, templateID: null,
        templates: []
    }

    componentDidMount() {
        const role = localStorage.getItem('role');

        if (role !== 'student') {
            this.getClasses();
        }
    }

    /* SELECT FIELDS */

    getClasses = async () => {
        this.setState({ APIBeingCalled: true });
        try {
            const response = await this.props.actions.fetchClasses();

            if (response) {
                this.setState({
                    classes: response
                });
            }
        } catch (error) {
            this.showToast(this.state.localize('shared.static.warning'), error?.response?.data?.message)
        }

        this.setState({ APIBeingCalled: false });
    }

    getTemplates = async () => {
        this.setState({ APIBeingCalled: true });
        try {
            const response = await this.props.actions.getTemplates(this.state.classID, 0, 10);

            if (response) {
                this.setState({
                    templates: response.list
                });
            }
        } catch (error) {
            this.showToast(this.state.localize('shared.static.warning'), error?.response?.data?.message)
        }

        this.setState({ APIBeingCalled: false });
    }

    onSelectClass = (e) => {
        if (e.target.value) {
            if (e.target.value !== "Select Class") {
                this.setState({ classID: e.target.value }, () => this.getTemplates());
            } else {
                this.setState({ classID: null });
            }
        }
    }

    onSelectTemplate = (e) => {
        if (e.target.value) {
            if (e.target.value !== "Select Category") {
                this.setState({ templateID: e.target.value });
            } else {
                this.setState({ templateID: null });
            }
        }
    }

    /* TOASTS */

    showToast = (title, message) => {
        this.setState({
            toast: {
                show: true,
                title: title,
                message: message || this.state.localize('shared.static.serverError')
            }
        });

        setTimeout(this.hideToast, 3000);
    }

    hideToast = () => {
        this.setState({
            toast: {
                show: false,
                message: null,
                title: null
            },
            APIBeingCalled: false
        })
    }

    /* APIs */

    getList = async () => {
        this.setState({ APIBeingCalled: true });
        try {
            const remarks = await this.props.actions.getRemarksOfStudents(this.state.classID, this.state.templateID);

            if (remarks.success && remarks.list.length > 0) {
                this.setState({
                    remarks: Array.from(remarks.list),
                    remarksCopy: Array.from(remarks.list)
                });
            } else {
                this.setState({
                    message: this.state.localize('progressReportRemarksComponent.static.noRemarks')
                });
            }
        } catch (error) {
            this.showToast(this.state.localize('shared.static.warning'), error?.response?.data?.message)
        }

        this.setState({ APIBeingCalled: false });
    }

    /* FIELDS */

    handleRemark = (event, index) => {
        if (index >= 0) {
            const remarks = [...this.state.remarks];
            const remark = {...this.state.remarks[index]};

            remark.remarks = event;

            remarks[index] = remark;

            this.setState({
                remarks
            })
        }
    }

    toggleSelection = (index) => {
        if (index >= 0) {
            const remarks = [...this.state.remarks];

            if (!remarks[index].selected) {
                remarks[index].selected = true;
            } else {
                remarks[index].selected = false;
            }

            this.setState({
                remarks
            })
        }
    }

    saveRemarks = async () => {
        this.setState({ APIBeingCalled: true });

        let counter = 0;
        const remarks = [...this.state.remarks];
        const remarksCopy = [...this.state.remarksCopy];

        try {
            for (let i = 0; i < remarks.length; i++) {
                if (remarks[i].remarks && remarks[i].remarks_id && remarks[i].remarks !== remarksCopy[i].remarks) {
                    const remark = await this.props.actions.updateRemark(remarks[i].remarks_id, remarks[i].remarks);

                    if (remark.success) {
                        counter++
                    }

                    continue;
                }

                if (remarks[i].remarks && !remarks[i].remarks_i && remarks[i].remarks !== remarksCopy[i].remarks) {
                    const remark = await this.props.actions.createRemark({
                        student_id: remarks[i].student_id,
                        class_id: remarks[i].class_id,
                        template_id: remarks[i].template_id,
                        remarks: remarks[i].remarks
                    });

                    if (remark.success) {
                        counter++
                    }

                    continue;
                }
            }
        } catch (error) {
            this.showToast(this.state.localize('shared.static.warning'), error?.response?.data?.message)
        }

        if (counter > 0) {
            this.showToast("Sucesss", this.state.localize('progressReportRemarksComponent.static.saved', { total: counter, out_of: remarks.length }))

            this.getList();
        }

        this.setState({ ...this.state, APIBeingCalled: false, remarksModal: { remarks: '', show: false } });
    }

    /* REMARKS MODAL */

    remarksModalShowToggle = () => this.setState({ ...this.state, remarksModal: { show: !this.state.remarksModal.show } })

    remarkEveryStudent = async () => {
        this.setState({ APIBeingCalled: true });

        let counter = 0;
        let selected = 0;
        let remarks = [...this.state.remarks];

        remarks = remarks.map((remark) => {
            if(remark.selected) {
                remark.remarks = this.state.remarksModal.remark;
            }

            return remark;
        });

        this.setState({
            remarks
        }, () => remarks = this.state.remarks)

        try {
            for (let i = 0; i < remarks.length; i++) {
                if (remarks[i].remarks && remarks[i].remarks_id && remarks[i].selected) {
                    selected++;
                    const remark = await this.props.actions.updateRemark(remarks[i].remarks_id, remarks[i].remarks);

                    if (remark.success) {
                        counter++
                    }

                    continue;
                }

                if (remarks[i].remarks && !remarks[i].remarks_id && remarks[i].selected) {
                    selected++;
                    const remark = await this.props.actions.createRemark({
                        student_id: remarks[i].student_id,
                        class_id: remarks[i].class_id,
                        template_id: remarks[i].template_id,
                        remarks: remarks[i].remarks
                    });

                    if (remark.success) {
                        counter++
                    }

                    continue;
                }
            }
        } catch (error) {
            this.showToast(this.state.localize('shared.static.warning'), error?.response?.data?.message)
        }

        if (selected <= 0) {
            this.showToast(this.state.localize('shared.static.warning'), this.state.localize('progressReportRemarksComponent.static.selected'))
        }

        if (counter > 0 && selected > 0) {
            this.showToast("Sucesss", this.state.localize('progressReportRemarksComponent.static.saved', { total: counter, out_of: selected }))

            this.getList();
        }

        this.setState({ APIBeingCalled: false });
    }

    remarkText = (data) => {
        let remarksModal = { ...this.state.remarksModal };

        remarksModal.remark = data;

        this.setState({
            ...this.state,
            remarksModal
        })
    }

    deleteRemark = async (index) => {
        this.setState({ APIBeingCalled: true });

        const remark = {...this.state.remarks[index]};

        if(!remark.remarks_id) {
            this.showToast(this.state.localize('shared.static.warning'), "This studen't doesnt have remark")

            return;
        } else {
            try {
                const remarks = await this.props.actions.deleteProgressReportRemark(remark.remarks_id);
    
                if (remarks.success) {
                    this.getList();

                    this.showToast(this.state.localize('shared.static.success'), remarks.message)
                } 
            } catch (error) {
                this.showToast(this.state.localize('shared.static.warning'), error?.response?.data?.message)
            }
        }

        this.setState({ APIBeingCalled: false });
    }

    isOneSelected = () => this.state.remarks.some((remark) => remark.selected);

    renderRemarksListing = () => {
        if (this.state.remarks.length < 1) {
            return <Container fluid={true}>
                <Row>
                    <Col md={12} lg={12} sm={12} xs={12} className="component-message width-100 align-items-center justify-content-center">
                        <h4>{this.state.message}</h4>
                    </Col>
                </Row>
            </Container>;
        }

        return <div className="remarks-container mt-3">
            {
                this.state.remarks.length > 0 &&
                this.state.remarks.map((remark, index) => {
                    return (
                        <Row key={index} className="mt-2">
                            <Col md={12} lg={12} xs={12} sm={12} className="d-flex flex-row">
                                <input type="checkbox" onChange={() => this.toggleSelection(index)} />

                                <h5 className="student-name">
                                    {remark.name}
                                </h5>
                            </Col>

                            <Col md={12} lg={12} xs={12} sm={12} className="d-flex gap-5">   
                            <CKEditor 
                                            editor={ClassicEditor}                                             
                                            data={remark.remarks || ''}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                this.handleRemark(data,index)
                                            }}
                                        />
                                {/* <textarea value={remark.remarks || ''} onChange={(e) => this.handleRemark(e, index)}></textarea> */}
                               
                            </Col>

                            <Col md={12} lg={12} xs={12} sm={12}>
                            <Button variant="danger" onClick={() => this.deleteRemark(index)}> <FontAwesomeIcon icon={faTrashAlt} /></Button>
                                <hr />
                            </Col>
                        </Row>
                    )
                })
            }
        </div>

    }

    render() {
        return (
            <div>
                <HeaderBar />
                <div
                    aria-live="polite"
                    aria-atomic="true">
                    <div
                        style={{
                            marginTop: '10px',
                            marginRight: '10px',
                            position: 'fixed',
                            top: 0,
                            right: 0,
                            zIndex: '9999',
                            width: '280px'
                        }}
                    >
                        <Toast show={this.state.toast.show}>
                            <Toast.Header>
                                <strong className="mr-auto">{this.state.toast.title}</strong>
                            </Toast.Header>
                            <Toast.Body>{this.state.toast.message}</Toast.Body>
                        </Toast>
                    </div>
                </div>
                <Container fluid={true}>
                    <Row>
                        <Col md={3} lg={3} xs={12} sm={12}>
                            <h4>
                                {this.state.localize('progressReportRemarksComponent.static.title')}
                            </h4>
                        </Col>

                        <Col md={9} lg={9} xs={12} sm={12} className="d-flex justify-content-end gap-5">
                            <select className="form-control form-sm" disabled={this.state.APIBeingCalled} onChange={this.onSelectClass}>
                                <option>{this.state.localize('shared.texts.select', { type: this.state.localize('shared.static.class') })}</option>
                                {this.state.classes.map((c) => <option value={c._id} key={c._id}>{c.name}</option>)}
                            </select>

                            <select className="form-control form-sm" disabled={this.state.APIBeingCalled || this.state.classes.length === 0 || !this.state.classID} onChange={this.onSelectTemplate}>
                                <option>{this.state.localize('shared.texts.select', { type: this.state.localize('shared.static.session') })}</option>
                                {this.state.templates.map((t, i) => <option value={t._id} key={i}>{t.session}</option>)}
                            </select>

                            <Button variant="primary" onClick={this.getList} disabled={this.state.APIBeingCalled || (!this.state.classID || !this.state.templateID)}> {this.state.localize('shared.buttons.search')} </Button>

                            <Button variant="primary" onClick={this.remarksModalShowToggle} disabled={this.state.remarks.length < 1 || !this.isOneSelected()}> {this.state.localize('progressReportRemarksComponent.buttons.remarkAll')}</Button>
                        </Col>
                    </Row>

                    <>
                        {
                            !this.state.APIBeingCalled ?
                                this.renderRemarksListing()
                                :
                                <div className="text-xl-center">
                                    <FontAwesomeIcon style={{ width: '200px', height: '200px', color: '#5bc0de' }} icon={faCircleNotch} spin />
                                </div>
                        }
                    </>

                    {
                        !this.state.remarksModal.show && this.state.remarks.length > 0 && <div className="footer">
                            <Button variant="primary" onClick={this.isOneSelected ? this.saveRemarks : this.remarkEveryStudent} disabled={this.state.remarks.length < 1}> {this.state.localize('shared.buttons.save')}</Button>
                        </div>
                    }

                    <RemarkAllModal toggleModal={this.remarksModalShowToggle} updateRemark={this.remarkText} show={this.state.remarksModal.show} remark={this.remarkEveryStudent} />
                </Container>
            </div>
        )
    }
}

function mapStateToProps() {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            fetchClasses: fetchClasses,
            getRemarksOfStudents: getRemarksOfStudents,
            updateRemark: updateRemark,
            getTemplates: getTemplates,
            createRemark: createRemark,
            deleteProgressReportRemark: deleteProgressReportRemark
        }, dispatch)
    };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(progressReportRemarks));