import * as types from "./types";

export const loginRequest = () => ({
    type: types.LOGIN_REQUEST,
});

export const loginSuccess = (data) => ({
    type: types.LOGIN_SUCCESS,
    payload: data
});

export const UserloginSuccess = (data) => ({
    type: types.USER_LOGIN,
    payload: data,
});

export const loginFailed = (err) => ({
    type: types.LOGIN_FAILED,
    payload: err
});


export const saveToken = (data) => ({
    type: types.SAVE_TOKEN,
    payload: data
});

export const unauthorize = () => ({
    type: types.UNAUTHORIZED_USER,
});

export const isAuthorizing = () => ({
    type: types.ISAUTHORIZING_USER
})

export const logout = () => ({
    type: types.LOGOUT,
});
