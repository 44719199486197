import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Modal, Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faCircleNotch } from '@fortawesome/free-solid-svg-icons'

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { updateFeed } from '../components/duck/operations';
import './style.scss';
import { withTranslation } from 'react-i18next';

class feedback extends React.Component {
    state = {
        classes: [],
        isFetchingClasses: false,
        isUpdateFeedback: false,
        feedContent: "",
        class: null,
        classID: null,
        localize: this.props.t
    }

    handleDescription = (data) => { this.setState( {feedContent: data}); }
    onClose = e => { this.props.onClose && this.props.onClose(e); };
    onSelectClass = (classID) => { this.setState({ class: classID }) }

    updateFeedbackAPI = async () => {
        this.setState({ isUpdateFeedback: true })
        try {
            const response = await this.props.actions.updateFeed(this.state.classID, this.state.feedContent, this.state.feed._id);
            
            if(response.status === 200) {
                this.props.onUpdate();

                this.props.handleMessage(this.state.localize('shared.texts.updated', { module: this.state.localize('pages.quiz', {action: ''}) }), this.state.localize('shared.static.success'))
            }
        } catch (error) {
            this.setState({ error: error.message || this.state.localize('shared.static.serverError') });
        }
        this.setState({ isUpdateFeedback: false })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.feed !== this.props.feed) {
            console.log(this.props)
            this.setState({
                classID: nextProps?.classID,
                feed: nextProps?.feed || '',
                feedContent: nextProps?.feed?.message || ''
            })
        }
    }

    render() {
        const { isUpdateFeedback, classID, feed } = this.state;
        return (
            <div>
                <Modal size="lg" show={this.props.show} animation={false}>
                    <Modal.Header >
                        <Modal.Title>{this.state.localize('pages.classFeed', {action: this.state.localize('shared.static.update')})}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col xs={12} md={6} lg={4}>
                                    <Modal.Body>
                                        {/* <Class default="" onSelect={this.onSelectClass}/> */}
                                        <select className="form-control form-sm" onChange={this.onSelectClass} value={classID}>
                                            <option>{this.state.localize('pages.classFeed')}</option>
                                            {this.props.classes.map((c) => {
                                                return (
                                                    <option value={c._id} key={c._id}>{c.name}</option>
                                                )
                                            })}
                                        </select>
                                    </Modal.Body>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} md={12} lg={12}>
                                    <Modal.Body>
                                        <CKEditor 
                                            editor={ClassicEditor} 
                                            data={feed?.message}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                this.handleDescription(data)
                                            }}
                                        />
                                    </Modal.Body>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={this.props.onClose}>
                            { this.state.localize('shared.buttons.close') }
                    </Button>
                        <Button
                            variant="success"
                            onClick={this.updateFeedbackAPI}
                            disabled={isUpdateFeedback || this.state.feedContent.length === 0}>
                            {!isUpdateFeedback ? <FontAwesomeIcon icon={faPlus} /> : <FontAwesomeIcon icon={faCircleNotch} spin />}
                            {!isUpdateFeedback ? (' ' + this.state.localize('shared.buttons.update')) : (' ' + this.state.localize('shared.buttons.wait'))}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps() {
    return {
        /* classID: state.componentReducer.classID, */
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            updateFeed: updateFeed
        }, dispatch)
    };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(feedback));