import React from 'react';
import { withTranslation } from 'react-i18next';

import HeaderBar from '../../components/HeaderBar';
import { Container, Row, Col, Toast } from 'react-bootstrap';

import { fetchClasses, getStudentProgressReport } from '../../components/duck/operations';


import './style.scss';
import { bindActionCreators } from 'redux';
import { connect, FontAwesomeIcon, faCircleNotch } from '../../quiz';

class progressReportStudents extends React.Component {
    state = {
        message: this.props.t('progressReportStudentComponent.static.selectFilter'),
        classes: [],
        sessions: ['2020', '2021', '2022', '2023', '2024', '2025', '2026', ' 2027', '2028', '2029', '2030'],
        toast: {
            show: false,
            title: null,
            message: null
        },
        session: null, classID: null,
        reports: [],
        searchString: '',
        localize: this.props.t,
    }

    componentDidMount() {
        const role = localStorage.getItem('role');

        if (role !== 'student') {
            this.getClasses();
        }
    }

    getReports = async () => {
        this.setState({ APIBeingCalled: true });

        try {
            const reports = await this.props.actions.getStudentProgressReport(this.state.classID, this.state.session);

            if (reports.list.length > 0) {
                this.setState({ reports: reports.list });
            } else {
                this.setState({ reports: reports.list, message: this.props.t('progressReportStudentComponent.static.notFound') });
            }
        } catch (error) {
            this.showToast(this.state.localize('shared.static.warning'), error?.response?.data?.message)

            this.setState({
                reports: [],
                message: this.props.t('progressReportStudentComponent.static.selectFilter')
            })
        }

        this.setState({ APIBeingCalled: false });
    }

    getClasses = async () => {
        this.setState({ APIBeingCalled: true });
        try {
            const response = await this.props.actions.fetchClasses();

            if (response) {
                this.setState({
                    classes: response
                });
            }
        } catch (error) {
            this.showToast(this.state.localize('shared.static.warning'), error?.response?.data?.message)
        }

        this.setState({ APIBeingCalled: false });
    }

    /* SELECT FIELDS */


    onSelectClass = (e) => {
        if (e.target.value) {
            if (e.target.value !== "Select Class") {
                this.setState({ classID: e.target.value }, () => {
                    if (this.state.classID && this.state.session) {
                        this.getReports();
                    }
                });
            } else {
                this.setState({ classID: null });
            }
        }
    }

    onSelectSession = (e) => {
        if (e.target.value) {
            if (e.target.value !== "Select Session") {
                this.setState(
                    { session: e.target.value },
                    () => {
                        if (this.state.classID && this.state.session) {
                            this.getReports();
                        }
                    }
                );
            } else {
                this.setState({ session: null });
            }
        }
    }

    /* TOASTS */

    showToast = (title, message) => {
        this.setState({
            toast: {
                show: true,
                title: title,
                message: message || this.state.localize('shared.static.serverError')
            }
        });

        setTimeout(this.hideToast, 3000);
    }

    hideToast = () => {
        this.setState({
            toast: {
                show: false,
                message: null,
                title: null
            },
            APIBeingCalled: false
        })
    }

    /* INPUT FIELDS */
    searchStudents = (event) => {
        const searchString = event.target.value;

        this.setState({
            searchString: searchString
        })
    }

    matchesSearchString = (report) => JSON.stringify(report).toLowerCase().includes(this.state.searchString);

    viewReportDetails = (index) => {
        localStorage.setItem('student_progress_info', JSON.stringify(Object.assign({ class_id: this.state.classID}, this.state.reports[index])))

        return this.props.history.replace('progress-report-student/' + this.state.reports[index].student_id);
    }

    /* RENDER */

    renderRemarksListing = () => {
        if (this.state.reports.length < 1) {
            return <Container fluid={true}>
                <Row>
                    <Col md={12} lg={12} sm={12} xs={12} className="component-message width-100 align-items-center justify-content-center">
                        <h4>{this.state.message}</h4>
                    </Col>
                </Row>
            </Container>;
        }

        return <>
            {
                this.state.reports.length > 0 &&
                <Row className="mt-4">
                    <Col md={12} lg={12} xs={12} sm={12}>
                        <input type="text" placeholder={this.state.localize('progressReportStudentComponent.placeholders.searchStudents')} className="form-control form-sm" onChange={this.searchStudents} />
                    </Col>
                </Row>
            }

            {
                this.state.reports.length > 0 &&
                this.state.reports.map((report, index) => {
                    return (
                        this.matchesSearchString(report) &&

                        <Row key={index} className="mt-2 cursor-pointer" onClick={() => this.viewReportDetails(index)}>
                            <Col md={10} lg={10} xs={12} sm={12}>
                                <p>
                                    {report.firstName} {report.lastName} - {report.rollNumber}
                                </p>
                            </Col>

                            <Col md={2} lg={2} xs={12} sm={12} className="d-flex justify-content-end gap-5 d-flex flex-column">
                                <div className="d-flex justify-content-end gap-5 d-flex">
                                    <div className="d-flex justify-content-center align-items-center">
                                        {report.obtain_marks} / {report.total_marks}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    )
                })
            }
        </>

    }

    render() {
        return (
            <div>
                <HeaderBar />
                <div
                    aria-live="polite"
                    aria-atomic="true">
                    <div
                        style={{
                            marginTop: '10px',
                            marginRight: '10px',
                            position: 'fixed',
                            top: 0,
                            right: 0,
                            zIndex: '9999',
                            width: '280px'
                        }}
                    >
                        <Toast show={this.state.toast.show}>
                            <Toast.Header>
                                <strong className="mr-auto">{this.state.toast.title}</strong>
                            </Toast.Header>
                            <Toast.Body>{this.state.toast.message}</Toast.Body>
                        </Toast>
                    </div>
                </div>
                <Container fluid={true}>
                    <Row>
                        <Col md={3} lg={3} xs={12} sm={12}>
                            <h4>
                                { this.state.localize('progressReportStudentComponent.static.title') }
                            </h4>
                        </Col>

                        <Col md={9} lg={9} xs={12} sm={12} className="d-flex justify-content-end gap-5">
                            <select className="form-control form-sm" disabled={this.state.APIBeingCalled} onChange={this.onSelectClass}>
                                <option>{this.state.localize('shared.texts.select', { type: this.state.localize('shared.static.class') })}</option>
                                {this.state.classes.map((c) => <option value={c._id} key={c._id}>{c.name}</option>)}
                            </select>

                            <select className="form-control form-sm" disabled={this.state.APIBeingCalled} onChange={this.onSelectSession}>
                                <option>{this.state.localize('shared.texts.select', { type: this.state.localize('shared.static.session') })}</option>
                                {this.state.sessions.map((s, i) => <option value={s} key={i}>{s}</option>)}
                            </select>
                        </Col>
                    </Row>

                    <>
                        {
                            !this.state.APIBeingCalled ?
                                this.renderRemarksListing()
                                :
                                <div className="text-xl-center">
                                    <FontAwesomeIcon style={{ width: '200px', height: '200px', color: '#5bc0de' }} icon={faCircleNotch} spin />
                                </div>
                        }
                    </>

                </Container>
            </div>
        )
    }
}

function mapStateToProps() {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            fetchClasses: fetchClasses,
            getStudentProgressReport: getStudentProgressReport
        }, dispatch)
    };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(progressReportStudents));