import React from 'react';
import { withTranslation } from 'react-i18next';

import HeaderBar from '../../components/HeaderBar';
import { Container, Row, Col, Button, Toast } from 'react-bootstrap';


import './style.scss';
import { bindActionCreators } from 'redux';
import { connect, FontAwesomeIcon, faCircleNotch } from '../../quiz';
import { getStudentReport } from '../../components/duck/operations';

class listReports extends React.Component {
    state = {
        message: '',
        reports: [],
        toast: {
            show: false,
            title: null,
            message: null
        },
        localize: this.props.t
    }

    componentDidMount() {
        this.getListing();
    }

    getListing = async () => {
        this.setState({ APIBeingCalled: true })

        const classID = localStorage.getItem('classID')
        const studentID = localStorage.getItem('userID')

        if(!classID || !studentID) {
            this.props.history.push('/home');
        }

        try {
            const response = await this.props.actions.getStudentReport(classID, studentID);

            if(response.success) {
                this.setState({
                    reports: response.data?.templates
                })
            } else {
                this.setState({
                    message: this.state.localize('progressReportsStudentComponent.static.noReport')
                })
            }
        } catch (error) {
            this.showToast('Warning', error?.response?.data?.message || this.state.localize('shared.static.serverError'))
        }

        this.setState({APIBeingCalled: false})
    }

    showToast = (title, message) => {
        this.setState({
            toast: {
                show: true,
                title: title,
                message: message || this.state.localize('shared.static.serverError')
            }
        });

        setTimeout(this.hideToast, 3000);
    }

    hideToast = () => {
        this.setState({
            toast: {
                show: false,
                message: null,
                title: null
            },
            APIBeingCalled: false
        })
    }

    viewLink = (link) => window.open(link, '_blank')

    renderReportListing = () => {
        if (this.state.reports.length < 1) {
            return <Container fluid={true}>
                <Row>
                    <Col md={12} lg={12} sm={12} xs={12} className="component-message width-100 align-items-center justify-content-center">
                        <h4>{this.state.message}</h4>
                    </Col>
                </Row>
            </Container>;
        }

        return <>
            {
                this.state.reports.length > 0 &&
                this.state.reports.map((report, index) => {
                    return (
                        <Row key={index} className="mt-2">
                            <Col md={10} lg={10} xs={12} sm={12}>
                                <p>
                                    {report.title} - ({report.session})
                                </p>
                            </Col>

                            <Col md={2} lg={2} xs={12} sm={12} className="d-flex align-items-end gap-5 d-flex flex-column">
                                <Button variant="primary" onClick={() => this.viewLink(report.link)}>
                                    {this.state.localize('progressReportsStudentComponent.buttons.downloadReport')}
                                </Button>
                            </Col>
                        </Row>
                    )
                })
            }
        </>

    }

    render() {
        return (
            <div>
                <HeaderBar />
                <div
                    aria-live="polite"
                    aria-atomic="true">
                    <div
                        style={{
                            marginTop: '10px',
                            marginRight: '10px',
                            position: 'fixed',
                            top: 0,
                            right: 0,
                            zIndex: '9999',
                            width: '280px'
                        }}
                    >
                        <Toast show={this.state.toast.show}>
                            <Toast.Header>
                                <strong className="mr-auto">{this.state.toast.title}</strong>
                            </Toast.Header>
                            <Toast.Body>{this.state.toast.message}</Toast.Body>
                        </Toast>
                    </div>
                </div>
                <Container fluid={true}>
                    <Row>
                        <Col md={3} lg={3} xs={12} sm={12}>
                            <h4>
                                {this.state.localize('progressReportsStudentComponent.static.title')}
                            </h4>
                        </Col>
                    </Row>

                    <>
                        {
                            !this.state.APIBeingCalled ?
                                this.renderReportListing()
                                :
                                <div className="text-xl-center">
                                    <FontAwesomeIcon style={{ width: '200px', height: '200px', color: '#5bc0de' }} icon={faCircleNotch} spin />
                                </div>
                        }
                    </>
                </Container>
            </div>
        )
    }
}

function mapStateToProps() {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            getStudentReport: getStudentReport
        }, dispatch)
    };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(listReports));