import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Modal, Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faCircleNotch } from '@fortawesome/free-solid-svg-icons'

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Class from '../components/dropdowns/classList';
import { fetchClasses, addFeedback } from '../components/duck/operations';
import './style.scss';
import { withTranslation } from 'react-i18next';

class feedback extends React.Component {
    state = {
        classes: [],
        isFetchingClasses: false,
        isPostingFeedback: false,
        feedContent: "",
        class: null,
        localize: this.props.t
    }

    handleDescription = (data) => { this.setState( {feedContent: data}); }
    onClose = e => { this.props.onClose && this.props.onClose(e); };
    onSelectClass = (classID) => { this.setState({ class: classID }) }

    addFeedbackAPI = async () => {
        this.setState({ isPostingFeedback: true })
        try {
            await this.props.actions.addFeedback(this.state.class, this.state.feedContent);
            this.onClose()

            this.props.handleMessage(this.state.localize('shared.texts.created', { module: this.state.localize('pages.quiz', {action: ''}) }), this.state.localize('shared.static.success'))
        } catch (error) {
            this.setState({ error: error.message || this.state.localize('shared.static.serverError') });
        }
        this.setState({ isPostingFeedback: false })
    }

    render() {
        const { isPostingFeedback } = this.state;
        return (
            <div>
                <Modal size="lg" show={this.props.show} animation={false}>
                    <Modal.Header >
                        <Modal.Title>{this.state.localize('pages.classFeed', { action: this.state.localize('shared.static.create')})}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col xs={12} md={6} lg={4}>
                                    <Modal.Body>
                                        <Class default="" onSelect={this.onSelectClass}/>
                                    </Modal.Body>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} md={12} lg={12}>
                                    <Modal.Body>
                                        <CKEditor 
                                            editor={ClassicEditor} 
                                            data=""
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                this.handleDescription(data)
                                            }}
                                        />
                                    </Modal.Body>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={this.onClose}>
                            {this.state.localize('shared.buttons.close')}
                    </Button>
                        <Button
                            variant="success"
                            onClick={this.addFeedbackAPI}
                            disabled={isPostingFeedback || this.state.feedContent.length === 0}>
                            {!isPostingFeedback ? <FontAwesomeIcon icon={faPlus} /> : <FontAwesomeIcon icon={faCircleNotch} spin />}
                            {!isPostingFeedback ? (' ' + this.state.localize('shared.buttons.add')) : (' ' + this.state.localize('shared.buttons.wait'))}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        classID: state.componentReducer.classID,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            fetchClasses: fetchClasses,
            addFeedback: addFeedback
        }, dispatch)
    };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(feedback));