import React from 'react';
import { Button, Toast } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { faSearch, faPlus, faCircleNotch, faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment';
import 'moment/locale/ar';
import i18n from '../i18n';

import HeaderBar from '../components/HeaderBar';
import Can from '../config/Can';
import { fetchFeedback, fetchClasses, deleteFeed } from '../components/duck/operations';

import FeedbackForm from './feedback-form';
import FeedUpdateForm from './feedback-update';
import './style.scss';
import { withTranslation } from 'react-i18next';

class Feedback extends React.Component {
    state = {
        show: false,
        feedbacks: [],
        isFetchingFeedback: false,
        isDeletingFeed: false,
        messageBox: this.props.t('classFeedComponent.static.selectFilter'),
        classID: null,
        classes: [],
        toast: {
            show: false,
            message: null,
            title: null
        },
        localize: this.props.t
    }

    closeUpdateModal = () => {
        this.setState({ toggleUpdateModal: false, wantsToUpdateFeed: null })
    }

    closeUpdateModalAndFetch = () => {
        this.setState({ toggleUpdateModal: false, wantsToUpdateFeed: null })
        this.handleAPIFeedback();
    }

    componentDidMount() {
        const role = localStorage.getItem('role');

        if(role !== 'student') {
            this.getClasses();
        }
    }

    getClasses = async () => {
        this.setState({ isFetchingFeedback: true });
        try {
            const response = await this.props.actions.fetchClasses();

            if (response) {
                this.setState({
                    classes: response
                });
            }
        } catch (error) {
            this.setState({
                toast: {
                    show: !this.state.toast.show,
                    message: error.response.data.message || this.state.localize('shared.static.serverError'),
                    title: this.state.localize('shared.static.warning')
                }
            });

            setTimeout(this.hideToast(), 3000);
        }
        this.setState({ isFetchingFeedback: false });
    }

    showModal = () => { this.setState({ show: !this.state.show }); };
    onSelectClass = (e) => {
        if (e.target.value !== "Select Class") {
            this.setState({ classID: e.target.value })
        }
    }

    showUpdateModal = (i) => {
        if (i >= 0) {
            const feed = { ...this.state.feedbacks[i] };

            this.setState({
                toggleUpdateModal: !this.state.toggleUpdateModal,
                wantsToUpdateFeed: feed
            });
        }
    };

    deleteFeed = async (i) => {
        this.setState({
            isDeletingFeed: true
        })
        try {
            const feed = this.state.feedbacks[i];

            const response = await this.props.actions.deleteFeed(this.state.classID, feed.message, feed._id);

            if(response.status === 200) {
                this.setState({
                    toast: {
                        show: !this.state.toast.show,
                        message: this.state.localize('shared.texts.deleted', { module: this.state.localize('pages.classFeed', {action: ''}) }),
                        title: this.state.localize('shared.static.success')
                    }
                });

                setTimeout(this.hideToast, 3000);

                this.handleAPIFeedback();
            }
        } catch (error) {
            this.setState({
                toast: {
                    show: !this.state.toast.show,
                    message: error.response.data.message || this.state.localize('shared.static.serverError'),
                    title: this.state.localize('shared.static.warning')
                }
            });

            setTimeout(this.hideToast(), 3000);
        }
        this.setState({
            isDeletingFeed: false
        })
    }

    hideToast = () => {
        this.setState({
            toast: {
                show: false,
                message: null,
                title: null
            },
            isFetchingFeedback: false
        })
    }

    handleAPIFeedback = async () => {
        this.setState({ isFetchingFeedback: true })
        try {
            this.setState({ feedbacks: [] })
            let classID;
            if (localStorage.getItem('role') === 'teacher') { classID = this.state.classID; }
            else if (localStorage.getItem('role') === 'student') { classID = localStorage.getItem('classID'); }

            if (!classID) {
                this.setState({
                    toast: {
                        show: !this.state.toast.show,
                        message: this.state.localize('shared.texts.selectOne', {type: this.state.localize('shared.static.class')}),
                        title: this.state.localize('shared.static.warning')
                    }
                });

                setTimeout(this.hideToast, 3000);

                return;
            }

            let studentFeedback = await this.props.actions.fetchFeedback(classID);
            let shouldShowFeeds =  Array.isArray(studentFeedback?.list) && studentFeedback?.list?.length > 0 && studentFeedback.list.some((feed) => !feed.isDeleted);

            if(shouldShowFeeds) {
                let feedbacks = [];

                for (let i = 0; i < studentFeedback.list.length; i++) {
                    if (!studentFeedback.list[i].isDeleted) {
                        feedbacks.push(studentFeedback.list[i]);
                    }
                }

                this.setState({
                    feedbacks
                })
            } else {
                this.setState({
                    messageBox: this.props.t('classFeedComponent.static.noFeed')
                })
            }
            
        } catch (error) {
            this.setState({ error: error.message || this.state.localize('shared.static.serverError') });
        }
        this.setState({ isFetchingFeedback: false })
    }

    showErrorInToast = (message, title) => {
        if(message === '') return;

        this.setState({
            toast: {
                show: !this.state.toast.show,
                message,
                title
            }
        });

        setTimeout(this.hideToast, 3000);
    }

    renderFeedbackList(feedbacks) {
        if (feedbacks.length < 1) {
            return <div className="list-container-message-box"> <h3> { this.state.localize('classFeedComponent.static.selectFilter') } </h3> </div>
        }
        return <div className="feedback-list__wrapper">
            <div className="feedback-list__heading">
                <b>{this.state.localize('classFeedComponent.tableHeadings.teacher')}</b>
                <b>{this.state.localize('classFeedComponent.tableHeadings.date')}</b>
                <b>{this.state.localize('classFeedComponent.tableHeadings.notice')}</b>
                <b className="text-right"></b>
            </div>
            {}
            {/* {feedbacks.forEach(feedback => feedback.message)} */}
            {
                feedbacks && feedbacks.map((feedback, i) => {
                    return (
                        !feedback.isDeleted &&
                        <div className="feedback-list__row" key={i}>
                            <span>{feedback.teacherInfo.firstName + ' ' + feedback.teacherInfo.lastName}</span>
                            <span>{moment(feedback.date).locale(i18n.language).format('MM/DD/YYYY')}</span>
                            <span dangerouslySetInnerHTML={{ __html: feedback.message }}></span>
                            <span className="text-right">
                                <Can I='delete' a='Feedback'>
                                    <Button variant="danger" disabled={ this.state.isDeletingFeed } style={{ margin: '0px 5px' }} id={feedback._id} onClick={() => this.deleteFeed(i)}> <FontAwesomeIcon icon={faTrashAlt} /> {this.state.localize('shared.buttons.delete')}</Button>
                                </Can>
                                <Can I="update" a='Feedback'>
                                    <Button variant="info" disabled={ this.state.isDeletingFeed } style={{ margin: '0px 5px' }} id={feedback._id} onClick={() => this.showUpdateModal(i)}> <FontAwesomeIcon icon={faEdit} /> {this.state.localize('shared.buttons.update')}</Button>
                                </Can>
                            </span>
                        </div>
                    )
                })
            }
        </div>
    }

    render() {
        const { isFetchingFeedback, feedbacks, show, toggleUpdateModal, classes, classID, wantsToUpdateFeed } = this.state
        return (
            <div>
                <HeaderBar />
                <div
                    aria-live="polite"
                    aria-atomic="true">
                    <div
                        style={{
                            marginTop: '10px',
                            marginRight: '10px',
                            position: 'fixed',
                            top: 0,
                            right: 0,
                            zIndex: '9999',
                            width: '280px'
                        }}
                    >
                        <Toast show={this.state.toast.show}>
                            <Toast.Header>
                                <strong className="mr-auto">{ this.state.toast.title }</strong>
                            </Toast.Header>
                            <Toast.Body>{this.state.toast.message}</Toast.Body>
                        </Toast>
                    </div>
                </div>
                <div className="feedback-list__container">
                    <div className="feedback-list__header">
                        <h3>{this.state.localize('pages.classFeed', { action: '' })}</h3>
                        <div className="action-button__wrapper">
                            <Can I='read' a='Class'>
                                {/* <Class default="" onSelect={this.onSelectClass}/> */}
                                <select className="form-control form-sm" style={{ width: '50%' }} disabled={isFetchingFeedback} onChange={this.onSelectClass}>
                                    <option>{ this.state.localize('shared.texts.select', {type: this.state.localize('shared.static.class')}) }</option>
                                    {classes.map((c) => {
                                        return (
                                            <option value={c._id} key={c._id}>{c.name}</option>
                                        )
                                    })}
                                </select>
                            </Can>
                            <Button
                                className="searchbutton"
                                onClick={this.handleAPIFeedback}
                                disabled={isFetchingFeedback}>
                                {!this.state.isFetchingFeedback ? <FontAwesomeIcon icon={faSearch} /> : <FontAwesomeIcon icon={faCircleNotch} spin />}
                                {!this.state.isFetchingFeedback ? (' ' + this.state.localize('shared.buttons.search')) : (' ' + this.state.localize('shared.buttons.wait'))}
                            </Button>
                            <Can I='create' a='Feedback'>
                                <Button className="search-button" onClick={() => { this.showModal() }}> <FontAwesomeIcon icon={faPlus} /> {this.state.localize('pages.classFeed', { action: '' })} </Button>
                            </Can>
                        </div>
                    </div>

                    <FeedbackForm onClose={this.showModal} show={show} handleMessage={this.showErrorInToast}/>
                    <FeedUpdateForm onClose={this.closeUpdateModal} onUpdate={this.closeUpdateModalAndFetch} show={toggleUpdateModal} feed={wantsToUpdateFeed} classID={classID} classes={classes} handleMessage={this.showErrorInToast}/>

                    {/* <Modal size="lg" show={this.state.show} onHide={this.hideModal} animation={false}> <FeedbackForm/> </Modal> */}

                    {
                        isFetchingFeedback ?
                            <div className="text-xl-center">
                                <FontAwesomeIcon style={{ width: '200px', height: '200px', color: '#5bc0de' }} icon={faCircleNotch} spin />
                            </div> :
                            this.renderFeedbackList(feedbacks)
                    }

                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        classID: state.componentReducer.classID,
        feedbackList: state.componentReducer.feedbackList,
        pending: state.componentReducer.pending
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            fetchFeedback: fetchFeedback,
            fetchClasses: fetchClasses,
            deleteFeed: deleteFeed
        }, dispatch)
    };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Feedback));

/*
<span>{feedback.teacher.map(feedback => <p>{feedback.date}</p> )}</span>
<span>{feedback.teacher.map(feedback => <p>{feedback.message}</p> )}</span>

        let studentFeedback;
            if( localStorage.getItem('role') === 'teacher' ){
                studentFeedback = await this.props.actions.fetchFeedback(this.props.classID);
            }
            else if( localStorage.getItem('role') === 'student' ){
                studentFeedback = await this.props.actions.fetchFeedback(localStorage.getItem('classID'));
            }

            {feedbacks._id ?
            <div>
                {feedbacks && feedbacks.map((feedback) => {
                        return (
                        <div className="feedback-list__row" key={feedback._id} id={feedback._id}>
                            <span>F1</span>
                            <span>f2</span>
                            <span>F3</span>
                            <span className="text-right">
                                <Can I='delete' a='Resource'>
                                    <Button variant="danger" id={feedback._id} onClick={() => this.handleDeleteResource(feedback)}> <FontAwesomeIcon icon={faTrashAlt} /> Delete</Button>
                                </Can>
                            </span>
                    </div>
                    )})}
                </div>
                : ""}

                <div classname="feedback-list__wrapper">
                    <div className="feedback-list__heading">
                        <b>Date</b>
                        <b>Teacher Name</b>
                        <b>Notice</b>
                    </div>
                </div>

                {feedbackList.map(feedback =>
                        {return(
                                <div className="feedbackrow" key={feedback._id}>
                                <span>{feedback.teacherInfo[0].firstName}</span>
                                <span>{feedback.teacherInfo[0].lastName}</span>
                                <span>{feedback.teacherInfo[0].teacherID}</span>
                        </div>)})}

                        {feedbackList.map(feedback =>
                        {return(
                                <div className="feedbackrow" key={feedback._id}>
                                <span>{feedback.teacherInfo[1].firstName}</span>
                                <span>{feedback.teacherInfo[1].lastName}</span>
                                <span>{feedback.teacherInfo[1].teacherID}</span>
                        </div>)})} */


/*
                    {feedbackList.map(feedback =>
                        {return(
                                <div className="feedbackrow" key={feedback._id}>
                                <span>{feedback.teacherInfo[0].firstName}</span>
                                <span>{feedback.teacherInfo[0].lastName}</span>
                                <span>{feedback.teacherInfo[0].teacherID}</span>
                        </div>)})}

                    {feedbackList && feedbackList.map(feedback =>
                        <div>
                            {feedback.teacherInfo.map(feedback =>
                                <div><span>{feedback.firstName}</span></div>
                                )}
                            {feedback.teacherInfo.map(feedback =>
                                <div><span>{feedback.lastName}</span></div>
                                )}
                            {feedback.teacher.map(feedback =>
                                <div><span>{feedback._id}</span></div>
                            )}
                        </div>
                    )}

                    {feedbackList && feedbackList.map((feedback) =>
                        <div className="feedbackrow">
                            {feedback.teacherInfo.map(feedback =>
                                <div>
                                    <span>{feedback.firstName}</span>
                                    <span>{feedback.firstName}</span>
                                    <span>{feedback.firstName}</span>
                                </div>
                                )}
                        </div>
                    )}

                    <div className="feedback">
                                <div><span>CHECK1</span></div>
                                <div><span>CHECK2</span></div>
                                <div><span>CHECK3</span></div>
                        </div>

                    {feedbackList && feedbackList.map((feedback) =>
                        <div className="feedbackrow">
                            {feedback.teacher.map(feedback => { return (
                                <div>
                                    <span>{feedback.date}</span>
                                </div>
                            )})}
                            {feedback.teacher.map(feedback => { return (
                                <div>
                                    <span>{feedback._id}</span>
                                </div>
                            )})}
                    </div>
                    )}
 */